import SwiperCore, { Navigation, Pagination, EffectFlip } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import EducationContent from '../educationcontent/educationcontent.component';
import ArticleContent from '../articlecontent/articlecontent.component';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/effect-flip/effect-flip.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper components
SwiperCore.use([Navigation, Pagination, EffectFlip]);
function FlipContent({data,type}) {
  let slides = null;  
  if (type === "education"){
    if (data !== null && data !== undefined && data.length > 0){
      slides = data.map((education, index) => {
        return(
        <SwiperSlide key={education.id}>
          <EducationContent date={education.date} hour={education.hour} title={education.title} address={education.address} />
        </SwiperSlide>
        )
      });
    }
  }else{
    if (data !== null && data !== undefined && data.length > 0){
      slides = data.map((article, index) => {
        return(
        <SwiperSlide key={article.id}>
          <ArticleContent title={article.title} img={article.img} />
        </SwiperSlide>
        )
      });
    }
  }



  return (
    type === "education" ?
      (slides !== null) ? 
        <Swiper
          effect="flip"
          slidesPerView={3}
          navigation
          scrollbar={false}
          pagination={false}
          //pagination={{ clickable: true }}
          grabCursor={true}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
        {slides}
        </Swiper>
      :
      ''
    :
    (slides !== null) ? 
      <Swiper
        effect="flip"
        slidesPerView={3}
        navigation={false}
        scrollbar={false}
        //pagination={true}
        pagination={{ clickable: true }}
        grabCursor={true}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >
      {slides}
      </Swiper>
      :
      ''
  );
}

export default FlipContent;