export const highlightsData = {
  "educations":[
    {
      "id":"1",
      "date":"20 Aralık 2020",
      "hour":"14:00",
      "title":"İletişim Çözümleri",
      "address":"Arnavutköy, Tekkeci Sk. No:3-5 A, Beşiktaş / İstanbul"
    },
    {
      "id":"2",
      "date":"02 Ocak 2020",
      "hour":"11:00",
      "title":"Pazarlama Teknikleri",
      "address":"Arnavutköy, Tekkeci Sk. No:3-5 A, Beşiktaş / İstanbul"
    }    
  ],
  "articles":[
    {
      "id":"1",
      "title":"Toplumsal cinsiyet eşitliğinde dünyada durum ne ?",
      "img":"img1.jpg"
    },
    {
      "id":"2",
      "title":"Toplumsal cinsiyet eşitliğinde dünyada durum ne ?",
      "img":"img2.jpg"
    }    
  ]
};