export const DefinitionsActionTypes = {
  SET_STEP1: 'SET_STEP1',
  SET_STEP2: 'SET_STEP2',
  SET_STEP3: 'SET_STEP3',
  SET_STEP4: 'SET_STEP4',
  SET_STEP5: 'SET_STEP5'
}
// export const DefinitionsActionTypes = {
//   SET_BUSES: 'SET_BUSES',
//   SET_EXCEL_DATA: 'SET_EXCEL_DATA',
//   SET_DRIVERS: 'SET_DRIVERS',
//   SET_USERS: 'SET_USERS',
//   SET_SCHEDULES: 'SET_SCHEDULES',
//   SET_BUSROUTES: 'SET_BUSROUTES',
//   SET_ROUTEBUSDRIVERS: 'SET_ROUTEBUSDRIVERS'
// }