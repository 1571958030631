import React from 'react';
import './lastarticles.styles.scss';

const LastArticles = ({language}) => (

    <div className="lastArticlesWrapper">

      <div className="contentHeaderWrapper"> 
        <span className="titleInfo">{language.keywords.articles}</span>
        <h2 className="lastArticlesTitle">
          {language.keywords.recent} <span>{language.keywords.posts}</span>
        </h2>
      </div>

      <div className="lastArticlesContentWrapper">
        <div className="lastArticleCard">
          <span className="lastArticleDate">13 Ekim 2020</span>
          <h5 className="lastArticleTitle">Donec et laoreet felis. Sed dolor magna</h5>
          <p className="lastArticleContent">
            Praesent at tincidunt nisi. Donec feugiat dui sed lorem sodales, eget tristique sem pretium. Nulla sed turpis quis mauris vulputate malesuada. Curabitur blandit elit ligula, id commodo ipsum porttitor dapibus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.          
          </p>
          <hr className="lastArticleSep" />
          <span className="lastArticleWriter">Acelya Yılmaz</span>
        </div>

        <div className="lastArticleCard">
          <span className="lastArticleDate">13 Ekim 2020</span>
          <h5 className="lastArticleTitle">Vivamus ac dui iaculis, tincidunt risus id, accumsan libero.</h5>
          <p className="lastArticleContent">
            Praesent at tincidunt nisi. Donec feugiat dui sed lorem sodales, eget tristique sem pretium. Nulla sed turpis quis mauris vulputate malesuada. Curabitur blandit elit ligula, id commodo ipsum porttitor dapibus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.          
          </p>
          <hr className="lastArticleSep" />
          <span className="lastArticleWriter">Pelin Korkut</span>
        </div>
        
        <div className="lastArticleCard">
          <span className="lastArticleDate">13 Ekim 2020</span>
          <h5 className="lastArticleTitle">Nam porttitor dui vitae hendrerit laoreet</h5>
          <p className="lastArticleContent">
            Praesent at tincidunt nisi. Donec feugiat dui sed lorem sodales, eget tristique sem pretium. Nulla sed turpis quis mauris vulputate malesuada. Curabitur blandit elit ligula, id commodo ipsum porttitor dapibus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.          
          </p>
          <hr className="lastArticleSep" />
          <span className="lastArticleWriter">Gülşah Uran</span>
        </div>        

      </div>
    



    </div>
);

export default LastArticles;