import { LoginActionTypes } from './login.types';

const projects = [{value: "Soso", label: "Soso"}];

const INITIAL_STATE = {
  isLoggedIn: false,
  isLoading: false, 
  isProcessFinished:false,
  loginStart:false, 
  rememberme: false,
  showLoading: false,
  showLoadingPopup:false,
  selectedOption: null,
  projectname: null,
  username: null,
  password: null,
  usernameWarning: false,
  passwordWarning: false,
  projectWarning: false,
  User: null,
  error: null,
  generalError: null,
  projects: projects
  
}

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type){
    case LoginActionTypes.SIGN_IN:
      return {
        ...state,
        projectname: action.payload.projectname,
        password: action.payload.password,
        rememberMe: action.payload.rememberMe
      }

    case LoginActionTypes.SIGN_OUT:
      return INITIAL_STATE      

    case LoginActionTypes.LOGIN_INPUT_CHANGE:
      const { name, value } = action.event.target;
      return {
        ...state,
        [name]: value
      }

    case LoginActionTypes.SET_PAGE_LOADING:
      return {
        ...state,
        isLoading: action.value
      }

    case LoginActionTypes.SET_PROCESS_FINISHED:
      return {
        ...state,
        isProcessFinished: action.isProcessFinished
      }      
  

    case LoginActionTypes.LOGIN_SELECT_CHANGE:
      return{
        ...state,
        selectedOption: action.selectedOption,
        projectname: action.selectedOption.value

      }
    case LoginActionTypes.LOGIN_REMEMBER_CHECKED:
      return {
        ...state,
        rememberMe: action.event.target.checked
      }

    case LoginActionTypes.LOGIN_START:
      return{
        ...state,
        loginStart: action.payload
      }

    case LoginActionTypes.LOGIN_SUCCESS:
      return{
        ...state,
        showLoading: false,
        isLoggedIn: true,
        User: action.loginData.data.data,
        error: null
      }

    case LoginActionTypes.LOGIN_FAIL:
      return{
        ...state,
        showLoading: false,
        isLoggedIn: false,
        error: action.loginData
      }

    case LoginActionTypes.NETWORK_FAIL:
      return{
        ...state,
        generalError: action.payload,
        showLoading: false,
        isLoggedIn: false
      }

    case LoginActionTypes.SET_LOGIN_WARNING:
      const { warningName, warningStatus } = action.payload;
      return{
        ...state,
        [warningName]: warningStatus

      }

    case LoginActionTypes.SET_LOADING:
      return{
        ...state,
        showLoading: action.showLoading
      }

    case LoginActionTypes.SET_LOADING_POPUP:
      return{
        ...state,
        showLoadingPopup: action.showLoadingPopup
      }
  

    case LoginActionTypes.SET_PASS:
      return{
        ...state,
        User: {
          ...state.User,
          Pass:action.value
        }
      }

    case LoginActionTypes.SET_REPASS:
      return{
        ...state,
        User: {
          ...state.User,
          RePass:action.value
        }        
      }
  

    default:
      return state;
  }  
}

export default loginReducer;