import React from 'react';
import './copyrightlayer.styles.scss';

const CopyrightLayer = () => (
  <div className="copyrightLayer">
    <div className="copyrightContent">
      <span className="copyrightId">© 2020 Tüm hakları saklıdır.</span>
      <ul className="nav-bottom">
        <li className="nav-item-bottom">KVKK Aydınlatma Metni</li>
        <li className="nav-item-bottom">|</li>
        <li className="nav-item-bottom">Kullanım Koşulları</li>
      </ul>

    </div>
  </div>
);

export default CopyrightLayer;