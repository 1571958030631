import React from 'react';
import './articlecontent.styles.scss';

const ArticleContent = ({title,img}) => {
  return(
    <div className="articleContent">
      <img src={`/assets/images/${img}`} alt={title} />
      <h5 className="articleTitle">{title}</h5>
    </div>
  )
};

export default ArticleContent;