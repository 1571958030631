import React from 'react';
import NavBar from '../navbar/navbar.component';

import './header.styles.scss';



class Header extends React.Component {

  componentDidMount(){
    window.addEventListener("scroll", this.resizeHeaderOnScroll);
  }

  resizeHeaderOnScroll(){
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 200,
      navEl = document.getElementById("navID");

      if (distanceY > shrinkOn){
        navEl.classList.add("smaller");
      }else{
        navEl.classList.remove("smaller");
      }

  }

  render(){
    return(
      <>
        <NavBar menutype="smaller"  />
      </>
    );
  }
}

export default Header;
