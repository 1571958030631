import { compareAsc, parse, parseISO, format } from 'date-fns';

export const parseDateIso = (val) => {
  return parseISO(val);
}

export const parseDate = (val) => {
  return parse(val, 'yyyy-MM-dd', new Date());
}

export const parseDateSU = (val) => {
  return parse(val, 'dd/MM/yy', new Date());
}


export const formatNow = () => {
  return format(new Date(),'yyyy-MM-dd');
}

export const formatNowSU = () => {
  return format(new Date(),'dd/MM/yy');
}


//|| compareDates(otherServiceProps.RESERVATIONEND)
export const compareDates = (val1) => {
  let date1 = parse(val1, 'yyyy-MM-dd HH:mm', new Date());
  let date2 = new Date();

  //format(new Date(date1), '');
  let result = compareAsc(date1, date2);
  if (result > 0){
    return true;
  }else{
    return false;
  }
}

export const compareDatesSU = (val1,hour) => {
  let date1 = parse(val1+' '+hour, 'dd/MM/yy HH:mm', new Date());
  //let date2 = parse(formatNowSU(), 'dd/MM/yy', new Date());
  let date2 = new Date();

  // console.log(date1);
  // console.log(date2);

  //format(new Date(date1), '');
  let result = compareAsc(date1, date2);
  if (result < 0){
    return false;
  }else{
    return true;
  }
}


export const customShowLoadingLayer = () => {
  const loading = document.querySelector('.loadingWrapper');
  loading.classList.remove('hide');
  loading.classList.remove('show');
  loading.classList.add('show');
}
export const customHideLoadingLayer = () => {
  const loading = document.querySelector('.loadingWrapper');
  loading.classList.remove('show');
  loading.classList.remove('hide');
  loading.classList.add('hide');
}

export const setLoadingLayer = () => {
  const loading = document.querySelector('.edms-loading-screen');
  loading.classList.remove('loading-show');
  loading.classList.remove('loading-hide');
}

export const showLoading = () => {
  const loading = document.querySelector('.edms-loading-screen');
  loading.classList.add('loading-show');
  loading.classList.remove('loading-hide');
} 

export const hideLoading = () => {
  const loading = document.querySelector('.edms-loading-screen');
  loading.classList.remove('loading-show');
  loading.classList.add('loading-hide');
} 

export const showWarningLayer = () => {
  const warningLayer = document.querySelector('.warningLayer');
  warningLayer.classList.add('warning-show');
  warningLayer.classList.remove('warning-hide');
  setTimeout(() => {
    hideWarningLayer();
  },7000);
}

export const hideWarningLayer = () => {
  const warningLayer = document.querySelector('.warningLayer');
  warningLayer.classList.add('warning-hide');
  warningLayer.classList.remove('warning-show');
}


export const showWarningLayerPopup = () => {
  const warningLayerPopup = document.querySelector('.warningLayerPopup');
  try{
    warningLayerPopup.classList.add('warning-show');
    warningLayerPopup.classList.remove('warning-hide');
    setTimeout(() => {
      hideWarningLayerPopup();
    },7000);
  }catch(ex){}
}

export const hideWarningLayerPopup = () => {
  const warningLayerPopup = document.querySelector('.warningLayerPopup');
  try{
    warningLayerPopup.classList.add('warning-hide');
    warningLayerPopup.classList.remove('warning-show');
  }catch(ex){}
}


export const defaultPages = {
  servisyolcu: 'Servis Yolcu',
  servisbilgisi: 'Servis Bilgisi',
  servisler: 'Servisler / Rezervasyonlar',
  yolcuislemleri: 'Yolcu İşlemleri',
  raporlar: 'Raporlar',
  tanimlamalar: 'Tanımlamalar',
  servisislemleri: 'Servis İşlemleri',
  aractanimlari: 'Araç Tanımları',
  surucutanimlari: 'Sürücü Tanımları',
  kullanicitanimlari: 'Kullanıcı Tanımları',
  guzergahtanimlari: 'Rota / Güzergah Tanımları',
  zamanlamatanimlari: 'Zamanlama Tanımları'
}

export const validateEmail = (email) => {
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line
  if(email.match(mailformat)){
    return true;
  }else{
    return false;
  }
}

export const fromTimeToDate = (val) => {
    let d = new Date();
  if (val !== undefined || val !== "" || val !== null){
    try{
      let a = val.split(":");
      d.setHours(a[0]);
      d.setMinutes(a[1]);
    }catch(ex){

    }
    return d;
  }else{
    return d;
  }
}

export const generateID = () => {
  var d = new Date().getTime();
  if (window.performance && typeof window.performance.now === "function") {
      d += performance.now();; //use high-precision timer if available
  }
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16); //eslint-disable-line
  });
  return uuid;
}  

export const googleCaptchaKey = "";

export const serviceGroupBy = (array, key) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};