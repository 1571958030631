import React from 'react';
import './educationcontent.styles.scss';

const EducationContent = ({date,hour,title,address}) => {
  return(
    <div className="edutcationContent">
      <p className="educationDateTime">{date}</p>
      <p className="educationDateTime">{hour}</p>
      <h4 className="educationTitle">{title}</h4>
      <p className="educationAddress">{address}</p>
    </div>
  );
}

export default EducationContent;