import React from 'react';
import './textcontent.styles.scss';

const TextContent = () => (

  <div className="textContentWrapper">
    <div className="textContentLeftWrapper">
      <div className="textContentTitleWrapper">
        <span className="textContentTitleInfo">Biz Kimiz ?</span>
        <h2 className="textContentTitle">Vivamus bibendum <span>quis quam ut</span> scelerisque fusce.</h2>
      </div>
    </div>
    <div className="textContentRightWrapper">
      <p className="textContent">
        Sed suscipit maximus elit, quis tincidunt mauris consequat ac. Sed consectetur, risus rutrum tempus consectetur, mauris sapien sodales nibh, non varius diam enim quis justo. Nunc nec sem dapibus, dictum arcu ullamcorper, iaculis neque. Phasellus porta ipsum quis ligula blandit placerat. In efficitur at massa sit amet vestibulum.
      </p>
      <p className="textContent">
        Nullam neque metus, imperdiet et elit sed, commodo condimentum augue. Morbi eget dolor eleifend, convallis felis sed, pretium nulla. Curabitur erat mi, consectetur id tempus sed, euismod quis metus.       
      </p>
      <button className="textContentButton">Devamı</button>
    </div>
  </div>

);

export default TextContent;