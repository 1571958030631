export const regions = [ 
  {name: 'Europe', code: 'AF'}, 
  {name: 'North America', code: 'AX'}, 
  {name: 'Asia', code: 'AL'},
  {name: 'Middle East', code: 'AL'},
  {name: 'Africa', code: 'AL'},
  {name: 'Central America', code: 'AL'},
  {name: 'South America', code: 'AL'},
  {name: 'Oceania', code: 'AL'},
  {name: 'United States of America', code: 'AL'},
  {name: 'Central Europe', code: 'AL'}
];