import React from 'react';
import './sponsors.styles.scss';

const Sponsors = ({language}) => (
  <div className="sponsorsWrapper">
    <div className="contentHeaderWrapper"> 
      <span className="titleInfo">{language.keywords.ourSponsors}</span>
      <h2 className="sponsorsTitle">
        <span>{language.keywords.ourSupporters}</span>
      </h2>
    </div>    

    <div className="sponsorsWrapperContent">
      
        <div className="sponsorItem">
          <img className="sponsorImg" src="/assets/images/sponsor1.png" alt="" />
        </div>
        <div className="sponsorItem">
          <img className="sponsorImg" src="/assets/images/sponsor2.png" alt="" />
        </div>
        <div className="sponsorItem">
          <img className="sponsorImg" src="/assets/images/sponsor3.png" alt="" />
        </div>
        <div className="sponsorItem">
          <img className="sponsorImg" src="/assets/images/sponsor4.png" alt="" />
        </div>
        <div className="sponsorItem">
          <img className="sponsorImg" src="/assets/images/sponsor5.png" alt="" />
        </div>
        <div className="sponsorItem">
          <img className="sponsorImg" src="/assets/images/sponsor6.png" alt="" />
        </div>


    </div>

  </div>
);

export default Sponsors;