import SwiperCore, { Navigation, Pagination, Parallax } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import 'swiper/swiper.scss';
// import 'swiper/components/parallax/parallax.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';


// install Swiper components
SwiperCore.use([Navigation, Pagination, Parallax]);
function SliderParallax() {
  return (
        <Swiper
          parallax={true}
          navigation={false}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          <SwiperSlide>Slide 1</SwiperSlide>
          <SwiperSlide>Slide 2</SwiperSlide>        
        </Swiper>

  );
}

export default SliderParallax;