import React from 'react';
import FlipContent from '../flipcontent/flipcontent.component';
import { highlightsData } from '../../utils/highlights';
import './highlights.styles.scss';


const HighLights = ({language,highlights,onClose}) => {

  return(
        <div className={highlights ? 'hightLightsWrapper active' : 'hightLightsWrapper'}>
          <button className="highlightsCloseBtn" onClick={onClose}></button>
          <div className="hightLightsContentWrapper">
            <div className="highlightsEducationsWrapper">
              <h2 className="highlightsTitle">
                {language.keywords.upcoming} <span>{language.keywords.educations}</span>
              </h2>
              <div className="highlightsEducationsContent highlightsContent">
                <FlipContent data={highlightsData.educations} type="education" />
              </div>
            </div>
            <hr className="highlightSep" />
            <div className="highlightsArticlesWrapper">
              <h2 className="highlightsTitle">
                {language.keywords.featured} <span>{language.keywords.articles}</span>
              </h2>
              <div className="highlightsFeaturedContent highlightsContent articleContentWrapper">
                <FlipContent data={highlightsData.articles} type="article" />
              </div>
            </div>
          </div>        
        </div>


  );
}



export default HighLights;