import React, { useState } from 'react';
//import {useSelector} from "react-redux";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { project } from '../../utils/project';
import HighLights from '../highlights/highlights.component';
//import { language } from '../../utils/lang';
import './navbar.styles.scss';



const NavBar = ({language,menutype}) => {
  //const language = useSelector(state => state.project.language);
  //console.log(language);

  const [click, setClick] = useState(false);
  const [highlights, setHighlights] = useState(false);
  
  const handleClick = () => setClick(!click);
  const handleHighlights = () => setHighlights(!highlights);
  const closeMobileMenu = () => setClick(false);
  const closeHighlights = () => setHighlights(false);
  
  return(
    <>
    <nav id="navID" className="navbar">
      <div className="navbar-container">
        <Link to="/">
          <img className="navbar-logo" src={process.env.PUBLIC_URL + '/assets/images/soso-logo-normal.png'} alt={project.projectName} />
        </Link>

        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? 'fas' : 'fas fa-bars'} />
        </div>
        <HighLights language={language} highlights={highlights} onClose={closeHighlights} />
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>

        {
          click &&
          <button className="navmenuCloseBtn" onClick={closeMobileMenu}></button>
        }        

          <li className="nav-item">
            <Link
              to='/'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              {language.keywords.home}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to='/'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              {language.keywords.aboutUs}
            </Link>
          </li>    
          <li className="nav-item">
            <Link
              to='/'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              {language.keywords.findExpert}
            </Link>
          </li>    
          <li className="nav-item">
            <Link
              to='/'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              {language.keywords.ourEducations}
            </Link>
          </li>    
          <li className="nav-item">
            <Link
              to='/'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              {language.keywords.articles}
            </Link>
          </li>    
          <li className="nav-item">
            <Link
              to='/'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              {language.keywords.contactUs}
            </Link>
          </li>   
          <li className="nav-item nav-item-for-mobile">
            <Link
              to='/'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              {language.keywords.signIn}
            </Link>
          </li>             
          <li className="nav-item nav-item-for-mobile">
            <Link
              to='/'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              {language.keywords.signUp}
            </Link>
          </li>                       
          <li className="nav-item nav-item-for-mobile">
            <Link
              to='/'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              EN
            </Link>
          </li>                       
        </ul>
        <button className={highlights ? 'rightMenuButton hide' : 'rightMenuButton' } onClick={handleHighlights}>
          <i className="far fa-calendar-alt"></i>
        </button>
      </div>
      <ul className={click ? 'nav-menu-sub active' : 'nav-menu-sub'}>
        <li className="nav-item-sub">{language.keywords.signIn}</li>
        <li className="nav-item-sub">{language.keywords.signUp}</li>
        <li className="nav-item-sub">EN</li>
      </ul>
    </nav>
  </>
  )
};

const mapStateToProps = state => {
  return {
    language: state.project.language
  };
}

export default connect(mapStateToProps)(NavBar);

