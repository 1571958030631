import React from 'react';
import TextContent from '../textcontent/textcontent.component';
import './textcontentgallery.styles.scss';

const TextContentGallery = () => (

  <div className="textContentGalleryWraper">
    <div className="textContentGalleryInnerWrapper">
      <div className="textContentGalleryLeft">
        <div className="textContentGalleryTitleWrapper">
          <span className="textContentGalleryTitleInfo">Eğitimlerimiz ?</span>
          <h2 className="textContentGalleryTitle">Vel volutpat <span>sed purus</span> iaculis at ante turpis.</h2>
          <p className="textContent">
            Sed suscipit maximus elit, quis tincidunt mauris consequat ac. Sed consectetur, risus rutrum tempus consectetur, mauris sapien sodales nibh, non varius diam enim quis justo. Nunc nec sem dapibus, dictum arcu ullamcorper, iaculis neque.         
          </p>     
          <button className="textContentButton">Devamı</button>     
        </div>
      </div>
      <div className="textContentGalleryRight">
        <div className="galleryImgWrapper">
          <img className="" src="/assets/images/galleryimg1.jpg" alt="" />
        </div>
        <div className="galleryNav">
          <button><img src="/assets/images/arrow-left.png" alt="" /></button>        
          <button><img src="/assets/images/arrow-right.png" alt="" /></button>        
        </div>
      </div>
    </div>

  </div>

);

export default TextContentGallery;