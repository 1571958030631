export const options = [
  {
    "id": "ecefe410-3372-43b9-8503-84355b03e7a2",
    "name": "Joesph Stracke",
    "username": "Heath.Parker58",
    "email": "Lonnie93@hotmail.com",
    "address": {
      "street": "Destiny Creek",
      "suite": 34557,
      "city": "North Stanbury",
      "zipcode": "85617-4545",
      "geo": {
        "lat": "87.4891",
        "lng": "122.3165"
      }
    },
    "phone": "719.427.4408",
    "website": "annetta.com",
    "company": {
      "name": "Koelpin, Bins and Borer",
      "catchPhrase": "Polarised context-sensitive definition",
      "bs": "mission-critical disintermediate schemas"
    }
  },
  {
    "id": "637b3361-451d-4d2b-b23f-ae7647c7d4d2",
    "name": "Zetta Macejkovic",
    "username": "Whitney_Halvorson77",
    "email": "Ophelia_Deckow91@hotmail.com",
    "address": {
      "street": "Ardith Plaza",
      "suite": 83518,
      "city": "Rylanview",
      "zipcode": "45847",
      "geo": {
        "lat": "-32.0762",
        "lng": "17.7730"
      }
    },
    "phone": "186.869.9496",
    "website": "mckenna.biz",
    "company": {
      "name": "Ward, Kemmer and Predovic",
      "catchPhrase": "Compatible logistical monitoring",
      "bs": "sexy empower eyeballs"
    }
  },
  {
    "id": "21da5657-cc33-4e29-bd90-edde25448178",
    "name": "Madison Robel",
    "username": "Violet52",
    "email": "Linnea1@gmail.com",
    "address": {
      "street": "Jacobs Ports",
      "suite": 30743,
      "city": "West Maxineside",
      "zipcode": "69733-5726",
      "geo": {
        "lat": "53.5584",
        "lng": "163.3771"
      }
    },
    "phone": "1-734-164-7918 x154",
    "website": "holden.info",
    "company": {
      "name": "Hoeger Inc",
      "catchPhrase": "Mandatory web-enabled architecture",
      "bs": "cross-platform embrace supply-chains"
    }
  },
  {
    "id": "ec1fc62f-1109-4ca6-9521-d90f8c28f87a",
    "name": "Joan Wiegand",
    "username": "Erich.Volkman60",
    "email": "Penelope_Tromp@yahoo.com",
    "address": {
      "street": "Buck Overpass",
      "suite": 8841,
      "city": "Cathrineton",
      "zipcode": "72315-8536",
      "geo": {
        "lat": "-37.8183",
        "lng": "-42.3805"
      }
    },
    "phone": "1-483-090-4017",
    "website": "kraig.com",
    "company": {
      "name": "Carroll Inc",
      "catchPhrase": "Up-sized global time-frame",
      "bs": "mission-critical integrate infrastructures"
    }
  },
  {
    "id": "8acfbdcd-5a57-433f-aae8-d3fd58124fd7",
    "name": "Kayley Runolfsdottir",
    "username": "Marguerite.Franecki",
    "email": "Cristian.Monahan15@yahoo.com",
    "address": {
      "street": "Kuhic Vista",
      "suite": 17611,
      "city": "Port Herthafurt",
      "zipcode": "23449",
      "geo": {
        "lat": "-3.4827",
        "lng": "-101.8085"
      }
    },
    "phone": "279.817.1366",
    "website": "reyes.info",
    "company": {
      "name": "Kemmer, Ledner and Aufderhar",
      "catchPhrase": "Synergized systematic model",
      "bs": "turn-key deliver synergies"
    }
  },
  {
    "id": "8a77d8d2-6682-4098-80d1-f96837c7cc0f",
    "name": "Alverta Dietrich",
    "username": "Karelle_OReilly",
    "email": "Elwyn.Ward@yahoo.com",
    "address": {
      "street": "Koby Hollow",
      "suite": 9715,
      "city": "Bradtkeport",
      "zipcode": "49985-4244",
      "geo": {
        "lat": "35.7315",
        "lng": "15.0768"
      }
    },
    "phone": "1-623-076-2296 x76794",
    "website": "easton.biz",
    "company": {
      "name": "Maggio, Spencer and Legros",
      "catchPhrase": "Enhanced zero tolerance flexibility",
      "bs": "magnetic embrace users"
    }
  },
  {
    "id": "e497de8e-4a64-48f9-a3dd-ce66d36cf2d8",
    "name": "Hobart Reinger",
    "username": "Obie18",
    "email": "Ericka0@hotmail.com",
    "address": {
      "street": "Doyle Motorway",
      "suite": 36625,
      "city": "Bergeport",
      "zipcode": "45100",
      "geo": {
        "lat": "-33.5708",
        "lng": "86.4691"
      }
    },
    "phone": "006-196-0053",
    "website": "rosalia.net",
    "company": {
      "name": "Orn - Daugherty",
      "catchPhrase": "Function-based content-based policy",
      "bs": "world-class streamline e-tailers"
    }
  },
  {
    "id": "8bc700a6-34a1-4f67-99c2-1ad098abab51",
    "name": "Maddison McClure",
    "username": "Eliane_Rohan",
    "email": "Yesenia_Lynch2@yahoo.com",
    "address": {
      "street": "Tyrique Highway",
      "suite": 40769,
      "city": "North Anastasia",
      "zipcode": "64329-8148",
      "geo": {
        "lat": "74.5276",
        "lng": "-14.7435"
      }
    },
    "phone": "821.113.7211 x18364",
    "website": "tamara.info",
    "company": {
      "name": "Cruickshank - Osinski",
      "catchPhrase": "Pre-emptive intangible encoding",
      "bs": "holistic disintermediate eyeballs"
    }
  },
  {
    "id": "8042d25c-2f4b-4d3f-b29f-f6b4b6077905",
    "name": "Kory Pacocha",
    "username": "Darian.Swaniawski",
    "email": "Erna.Dach62@hotmail.com",
    "address": {
      "street": "Sigmund Rapids",
      "suite": 69298,
      "city": "Wymanborough",
      "zipcode": "80864-6826",
      "geo": {
        "lat": "33.9212",
        "lng": "19.0690"
      }
    },
    "phone": "1-330-229-7304 x7931",
    "website": "lizzie.org",
    "company": {
      "name": "Auer, Hamill and Koss",
      "catchPhrase": "Decentralized optimal function",
      "bs": "leading-edge optimize bandwidth"
    }
  },
  {
    "id": "202fc514-52ea-42d4-842f-543d0c0f8a20",
    "name": "Elsie Adams",
    "username": "Flo.Walter39",
    "email": "Harley55@yahoo.com",
    "address": {
      "street": "Welch Shoal",
      "suite": 59429,
      "city": "Padbergtown",
      "zipcode": "21322",
      "geo": {
        "lat": "-26.6030",
        "lng": "33.8425"
      }
    },
    "phone": "818.974.3316 x4004",
    "website": "augusta.biz",
    "company": {
      "name": "Hirthe - Oberbrunner",
      "catchPhrase": "Devolved exuding monitoring",
      "bs": "world-class scale portals"
    }
  },
  {
    "id": "a664f8f2-e232-4723-8719-45b9cc1aca25",
    "name": "Maritza Legros",
    "username": "Zachery7",
    "email": "May.McLaughlin@gmail.com",
    "address": {
      "street": "Champlin Mall",
      "suite": 84779,
      "city": "Anabelleton",
      "zipcode": "04737-4997",
      "geo": {
        "lat": "-88.0506",
        "lng": "-142.3667"
      }
    },
    "phone": "(527) 208-1944 x461",
    "website": "cedrick.com",
    "company": {
      "name": "Flatley, Bode and Huels",
      "catchPhrase": "Versatile bottom-line superstructure",
      "bs": "bricks-and-clicks harness architectures"
    }
  },
  {
    "id": "62f3b399-59cd-4081-b440-f670204fb623",
    "name": "Colby Tromp",
    "username": "Neil_Bayer",
    "email": "Allie89@hotmail.com",
    "address": {
      "street": "Toy Lock",
      "suite": 16332,
      "city": "East Maye",
      "zipcode": "23504",
      "geo": {
        "lat": "-22.6100",
        "lng": "30.9051"
      }
    },
    "phone": "(884) 270-3311 x514",
    "website": "stacy.name",
    "company": {
      "name": "Koch - Willms",
      "catchPhrase": "Cloned clear-thinking throughput",
      "bs": "impactful revolutionize metrics"
    }
  },
  {
    "id": "c1c15423-44e3-4417-8182-e986514097b2",
    "name": "Jakob Bartell",
    "username": "Maxime.Champlin61",
    "email": "Cyrus.Bednar73@yahoo.com",
    "address": {
      "street": "Hoppe Branch",
      "suite": 59890,
      "city": "Lindgrenville",
      "zipcode": "92002-1887",
      "geo": {
        "lat": "-37.0830",
        "lng": "140.0405"
      }
    },
    "phone": "175-463-6709",
    "website": "elliott.biz",
    "company": {
      "name": "Pacocha, Runte and Runolfsson",
      "catchPhrase": "User-friendly multi-state product",
      "bs": "front-end target synergies"
    }
  },
  {
    "id": "86a24c9e-7070-483f-87ad-3ca89532903b",
    "name": "Emily Ondricka",
    "username": "Hilbert.Rippin",
    "email": "Jennyfer_Gutmann33@gmail.com",
    "address": {
      "street": "Bette Viaduct",
      "suite": 97844,
      "city": "Leannonfurt",
      "zipcode": "28616",
      "geo": {
        "lat": "65.2944",
        "lng": "161.2466"
      }
    },
    "phone": "(743) 999-1092 x568",
    "website": "felton.net",
    "company": {
      "name": "Konopelski Inc",
      "catchPhrase": "Right-sized client-driven benchmark",
      "bs": "leading-edge benchmark partnerships"
    }
  },
  {
    "id": "353d1438-f3e2-4b95-8094-cff6454f9ad1",
    "name": "Guido Lindgren",
    "username": "Jerald.Hessel",
    "email": "Hobart20@gmail.com",
    "address": {
      "street": "Windler Overpass",
      "suite": 84124,
      "city": "New Stephanymouth",
      "zipcode": "20863",
      "geo": {
        "lat": "41.1346",
        "lng": "-62.2906"
      }
    },
    "phone": "917-107-5609 x2171",
    "website": "cullen.com",
    "company": {
      "name": "Roberts Inc",
      "catchPhrase": "Phased user-facing hub",
      "bs": "turn-key incubate infrastructures"
    }
  },
  {
    "id": "4a1d0886-7640-4fa6-9a70-f1321272076f",
    "name": "Cierra Crist",
    "username": "Karianne.Stroman89",
    "email": "Isac_Jacobson@yahoo.com",
    "address": {
      "street": "Brett Ways",
      "suite": 45455,
      "city": "Lake Kadinmouth",
      "zipcode": "01868",
      "geo": {
        "lat": "89.9676",
        "lng": "54.2104"
      }
    },
    "phone": "1-726-195-7765 x40700",
    "website": "cody.biz",
    "company": {
      "name": "Hegmann Inc",
      "catchPhrase": "Assimilated incremental portal",
      "bs": "24/7 productize initiatives"
    }
  },
  {
    "id": "e9640cc2-c85f-472c-a4c5-83df6ec7b36e",
    "name": "Nathaniel Hilpert",
    "username": "Aubree81",
    "email": "Brandy.Hodkiewicz46@gmail.com",
    "address": {
      "street": "Anais Rue",
      "suite": 64932,
      "city": "Lake Nichole",
      "zipcode": "48968-6549",
      "geo": {
        "lat": "-25.0067",
        "lng": "-120.1869"
      }
    },
    "phone": "(091) 951-7092 x844",
    "website": "alta.info",
    "company": {
      "name": "DuBuque - Schuppe",
      "catchPhrase": "Object-based zero defect function",
      "bs": "out-of-the-box productize metrics"
    }
  },
  {
    "id": "422ea2ab-b4f5-4b49-90d8-827c5fd640a1",
    "name": "Jesus Flatley",
    "username": "Marlee0",
    "email": "Dorothea.Fadel@gmail.com",
    "address": {
      "street": "Hilario Pines",
      "suite": 99738,
      "city": "North Justynport",
      "zipcode": "05481",
      "geo": {
        "lat": "66.2944",
        "lng": "-120.3123"
      }
    },
    "phone": "(240) 074-8777",
    "website": "lonnie.org",
    "company": {
      "name": "Rohan LLC",
      "catchPhrase": "Fully-configurable client-driven algorithm",
      "bs": "revolutionary leverage experiences"
    }
  },
  {
    "id": "dd9f88c0-6ea7-4f46-894c-4cb986173fb8",
    "name": "Mariela Bailey",
    "username": "Rod.Willms",
    "email": "Jedediah.Prosacco96@hotmail.com",
    "address": {
      "street": "Tyrell Curve",
      "suite": 65474,
      "city": "Lake Bethelburgh",
      "zipcode": "22666",
      "geo": {
        "lat": "-78.4765",
        "lng": "-131.8083"
      }
    },
    "phone": "(467) 351-8480",
    "website": "evan.org",
    "company": {
      "name": "Goyette, Yost and Grant",
      "catchPhrase": "Balanced bi-directional protocol",
      "bs": "best-of-breed reinvent metrics"
    }
  },
  {
    "id": "ac3f7361-adad-45df-ab11-d8448a41e4b0",
    "name": "Kari Ratke",
    "username": "Jared9",
    "email": "Granville48@gmail.com",
    "address": {
      "street": "Glover Streets",
      "suite": 70875,
      "city": "New Darylberg",
      "zipcode": "81810-7878",
      "geo": {
        "lat": "46.0171",
        "lng": "-40.2141"
      }
    },
    "phone": "(374) 487-1783",
    "website": "jerry.org",
    "company": {
      "name": "Beahan LLC",
      "catchPhrase": "Enhanced bifurcated secured line",
      "bs": "value-added e-enable supply-chains"
    }
  },
  {
    "id": "04c02784-ad73-45dd-afe7-25b56d15320e",
    "name": "Jules Hartmann",
    "username": "Ebba88",
    "email": "Catharine_Raynor@hotmail.com",
    "address": {
      "street": "Raphaelle Camp",
      "suite": 73537,
      "city": "New Ilene",
      "zipcode": "26684",
      "geo": {
        "lat": "-16.5156",
        "lng": "131.9922"
      }
    },
    "phone": "1-211-155-9588 x351",
    "website": "ayla.net",
    "company": {
      "name": "Olson, Kertzmann and Ankunding",
      "catchPhrase": "Team-oriented next generation benchmark",
      "bs": "front-end utilize infomediaries"
    }
  },
  {
    "id": "464b58ca-b736-4134-837c-0c17d578b36b",
    "name": "Camila Pollich",
    "username": "Curtis.Nader",
    "email": "Amira.Larson20@hotmail.com",
    "address": {
      "street": "Treutel Extension",
      "suite": 93667,
      "city": "Leonorhaven",
      "zipcode": "35184-6843",
      "geo": {
        "lat": "30.0610",
        "lng": "35.0556"
      }
    },
    "phone": "(823) 768-9142 x211",
    "website": "briana.org",
    "company": {
      "name": "Hirthe LLC",
      "catchPhrase": "Networked next generation initiative",
      "bs": "efficient maximize communities"
    }
  },
  {
    "id": "a2291c9e-c561-47ba-9890-6534b3244a8a",
    "name": "Ara Yundt",
    "username": "Antonio49",
    "email": "Cristal.Jast77@gmail.com",
    "address": {
      "street": "Luis Parkways",
      "suite": 70682,
      "city": "Herminiafort",
      "zipcode": "03934-5314",
      "geo": {
        "lat": "-19.4530",
        "lng": "-6.8492"
      }
    },
    "phone": "562-075-7396 x4228",
    "website": "zion.name",
    "company": {
      "name": "Mante, Lubowitz and Kilback",
      "catchPhrase": "Centralized local solution",
      "bs": "magnetic generate networks"
    }
  },
  {
    "id": "e4bd5954-a8c1-4b2a-b34f-c7ef83252601",
    "name": "Rowena Steuber",
    "username": "Delfina63",
    "email": "Waldo.Jerde44@gmail.com",
    "address": {
      "street": "Hermann Crescent",
      "suite": 85353,
      "city": "West Christelleville",
      "zipcode": "22104",
      "geo": {
        "lat": "-10.9983",
        "lng": "105.7355"
      }
    },
    "phone": "461-966-4408",
    "website": "amari.name",
    "company": {
      "name": "Hauck - Reichert",
      "catchPhrase": "Extended multi-state budgetary management",
      "bs": "open-source e-enable architectures"
    }
  },
  {
    "id": "d05072ed-9be4-46ca-acea-ecb0a5db446b",
    "name": "Henriette Stehr",
    "username": "Alivia61",
    "email": "Reina27@yahoo.com",
    "address": {
      "street": "Bahringer Walks",
      "suite": 28579,
      "city": "Port Kendraborough",
      "zipcode": "09954-0187",
      "geo": {
        "lat": "-15.6479",
        "lng": "-133.2464"
      }
    },
    "phone": "1-247-026-2203 x633",
    "website": "heloise.com",
    "company": {
      "name": "Hettinger, O'Reilly and Terry",
      "catchPhrase": "Programmable systematic moratorium",
      "bs": "customized grow functionalities"
    }
  },
  {
    "id": "87c931b8-edbf-453d-8285-a1e7ec37f308",
    "name": "Lexi Steuber",
    "username": "Cordell_Hirthe",
    "email": "Glenda.Hamill75@hotmail.com",
    "address": {
      "street": "Ritchie Plain",
      "suite": 33833,
      "city": "Irvingborough",
      "zipcode": "03745-6200",
      "geo": {
        "lat": "32.6405",
        "lng": "16.2350"
      }
    },
    "phone": "(843) 021-8586 x2119",
    "website": "deshaun.net",
    "company": {
      "name": "Wuckert Group",
      "catchPhrase": "User-friendly maximized array",
      "bs": "impactful extend vortals"
    }
  },
  {
    "id": "333389e9-6b4c-41ed-9919-9758a991ebd6",
    "name": "Raphaelle O'Hara",
    "username": "Tommie24",
    "email": "Bobbie.Price@gmail.com",
    "address": {
      "street": "Becker Point",
      "suite": 12301,
      "city": "Port Raulport",
      "zipcode": "88343-4507",
      "geo": {
        "lat": "-45.3043",
        "lng": "-10.6034"
      }
    },
    "phone": "1-365-518-7773 x61264",
    "website": "marisa.info",
    "company": {
      "name": "Kreiger, Champlin and Johnson",
      "catchPhrase": "Multi-tiered bottom-line structure",
      "bs": "dot-com streamline platforms"
    }
  },
  {
    "id": "f2685cca-e11b-4a55-b1b5-9766db2c0b24",
    "name": "Marguerite Brakus",
    "username": "Mason.Simonis20",
    "email": "Frederick.Rippin61@gmail.com",
    "address": {
      "street": "Kuphal Lodge",
      "suite": 37749,
      "city": "Schadenview",
      "zipcode": "88705",
      "geo": {
        "lat": "-78.8350",
        "lng": "126.5955"
      }
    },
    "phone": "383-140-1533 x89308",
    "website": "enola.org",
    "company": {
      "name": "Gerlach, Batz and Hoeger",
      "catchPhrase": "Diverse background artificial intelligence",
      "bs": "back-end incubate action-items"
    }
  },
  {
    "id": "f7db1c1f-f8f5-498a-b35f-bf2b7fd3ad42",
    "name": "Juliana Braun",
    "username": "Esther.Okuneva",
    "email": "Zachery.Aufderhar71@gmail.com",
    "address": {
      "street": "Lubowitz Ports",
      "suite": 22123,
      "city": "North Corrinetown",
      "zipcode": "12263",
      "geo": {
        "lat": "17.9504",
        "lng": "76.0628"
      }
    },
    "phone": "1-341-100-4419",
    "website": "glenda.biz",
    "company": {
      "name": "Buckridge Group",
      "catchPhrase": "Intuitive methodical help-desk",
      "bs": "B2B brand eyeballs"
    }
  },
  {
    "id": "2d2c9c26-d3b8-4309-8476-642eb0e830bb",
    "name": "Jorge Reichel",
    "username": "Kiara_Grady70",
    "email": "Enola.Towne47@hotmail.com",
    "address": {
      "street": "Percy Glens",
      "suite": 98139,
      "city": "Lake Lacyburgh",
      "zipcode": "13930-9077",
      "geo": {
        "lat": "71.3466",
        "lng": "-125.0934"
      }
    },
    "phone": "989-827-8820 x839",
    "website": "darrion.com",
    "company": {
      "name": "Kub, Bahringer and Roob",
      "catchPhrase": "Balanced 3rd generation instruction set",
      "bs": "real-time optimize partnerships"
    }
  }
];
