import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Home from './pages/home/home.page.component';

import WarningLayer from './components/warning-layer/warning-layer.component';
import LoadingLayer from './components/loading-layer/loading-layer.component';

//import { LoginActionTypes } from './redux/login/login.types';
import { language } from './utils/lang.js';
import { ProjectActionTypes } from './redux/project/project.types';
import { hideWarningLayer } from './utils/utils';
import './App.css';
import './res.scss';
class App extends React.Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){

    if (this.props.language === null){
      console.log("lang null");
      const defaultLang = language.default;
      const tmpLang = language[defaultLang];
      this.props.setLanguage(tmpLang);
      
    }else{
      // console.log(this.props.language.short);
      console.log(language);
      const defaultLang = this.props.language.short;
      const tmpLang = language[defaultLang];
      this.props.setLanguage(tmpLang);
    }

  }
  render(){
    return (
      <main>
        <Switch>
          <Route exact path='/'>
          {
            //  this.props.isLoggedIn && this.props.loginStart === false ? 
             <Redirect to="/home" />
          }
          </Route>
          <Route exact path='/home' component={Home} />
          <Route>
            <Redirect to="/home" />
          </Route>          
        </Switch>
        <LoadingLayer showLoading={this.props.showLoading} />
        <WarningLayer hideWarning={hideWarningLayer} text={this.props.warningMessage} warningType={this.props.warningMessageType} />
      </main>
    );
  
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    user: state.login.user,
    showLoading: state.login.showLoading,
    isLoading: state.login.isLoading,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage,
    language: state.project.language
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLanguage: (language) => dispatch({ type: ProjectActionTypes.SET_LANGUAGE, language: language})
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(App);
