import React from 'react';
import './newssubscriber.styles.scss';

const NewsSubscriber = () => (

    <div className="newsSubscriberWrapper">
        <div className="newsSubscriberLeft">
            <h2 className="newsSubscriberTitle">Bülten Aboneliği</h2>
        </div>
        <div className="newsSubscriberRight">
            <div className="newsSubscriberRightContent">
                <p className="newsSubscriberInfo">
                    Pellentesque faucibus, risus  urna nisl tempor nunc, et scelerisque quam risus tempor dolorat tincidunt malesuada.
                </p>
                <div className="newsInputWrapper">
                    <input type="text" name="" placeholder="Lütfen e-posta adresinizi girin." />
                    <button className="btn--outline buttonSubscriber">Üye Ol</button>
                </div>
            </div>
        </div>
    </div>

);

export default NewsSubscriber;