import React from 'react';
import './lastexperts.styles.scss';

const LastExperts = ({language}) => {
  return(
  <div className="lastExpertsWrapper">

    <div className="contentHeaderWrapper"> 
      <span className="titleInfo">{language.keywords.findExpert}</span>
      <h2 className="lastExpertsTitle">
        {language.keywords.lastJoined} <span>{language.keywords.experts}</span>
      </h2>
    </div>

    <div className="lastExpertsContentWrapper">
      <div className="lastExpertCard">
        <img className="lastExpertImg" src="/assets/images/profile1.png" alt="" />
        <h4 className="lastExpertName">Açelya Yılmaz</h4>
        <p className="lastExpertTitle">Satış Temsilcisi</p>
        <button className="lastExpertDetailBtn btn--outline">{language.keywords.getInTouch}</button>
      </div>
      <div className="lastExpertCard">
        <img className="lastExpertImg" src="/assets/images/profile2.png" alt="" />
        <h4 className="lastExpertName">Pelin Korkut</h4>
        <p className="lastExpertTitle">Veri Tabanı Uzmanı</p>
        <button className="lastExpertDetailBtn btn--outline">{language.keywords.getInTouch}</button>
      </div>
      <div className="lastExpertCard">
        <img className="lastExpertImg" src="/assets/images/profile3.png" alt="" />
        <h4 className="lastExpertName">Gülşah Uran</h4>
        <p className="lastExpertTitle">Mimari Projeler Yöneticisi</p>
        <button className="lastExpertDetailBtn btn--outline">{language.keywords.getInTouch}</button>
      </div>
      <div className="lastExpertCard">
        <img className="lastExpertImg" src="/assets/images/profile4.png" alt="" />
        <h4 className="lastExpertName">Banu Aktaş</h4>
        <p className="lastExpertTitle">Satış Müdürü</p>
        <button className="lastExpertDetailBtn btn--outline">{language.keywords.getInTouch}</button>
      </div>
      <div className="lastExpertCard">
        <img className="lastExpertImg" src="/assets/images/profile5.png" alt="" />
        <h4 className="lastExpertName">Eda Özdemir</h4>
        <p className="lastExpertTitle">Beslenme ve Diyet Uzmanı</p>
        <button className="lastExpertDetailBtn btn--outline">{language.keywords.getInTouch}</button>
      </div>                  

    </div>


  </div>
  );
}

export default LastExperts;