import React from 'react';
import CopyrightLayer  from '../copyrightlayer/copyrightlayer.component';
import './footer.styles.scss';

const Footer = () => (
  <React.Fragment>
    <footer className="footer">
      <div className="footerContentWrapper">
        <div className="footerContent bottomLogoLayer">
          

          <p className="bottomAbout">
            Aliquam nec mauris vel felis ornare vestibulum non eget erat. Praesent maximus enim quis dui fringilla egestas id id nisl. Etiam eu nisl rutrum, fermentum erat non, vehicula nisi
          </p>
        </div>
        <div className="footerContent">
          <h4 className="bottomTitle">İletişim Bilgileri</h4>
          <h6 className="bottomContactTitle">Adres</h6>
          <p className="bottomContactText">Donec et laoreet felis. Sed dolor magna, mattis sed mi pellentesque</p>
          <h6 className="bottomContactTitle">Telefon Numarası</h6>
          <p className="bottomContactText">+90 850 344 55 66</p>
          <h6 className="bottomContactTitle">E-Posta Adresi</h6>
          <p className="bottomContactText">info@sessizolmaz.com</p>
        </div>
        <div className="footerContent">
          <h4 className="bottomTitle">Sensiz Olmaz Sessiz Olmaz</h4>
          <ul className="bottomMenu">
            <li>Anasayfa</li>
            <li>Biz Kimiz</li>
            <li>Uzman Bul</li>
            <li>Eğitimlerimiz</li>
            <li>Makaleler</li>
            <li>İletişim</li>
            <li>Basında Biz</li>
            <li>Bağış Yapın</li>
          </ul>
        </div>
        <div className="footerContent">
          <h4 className="bottomTitle">Bizi Takip Edin</h4>
          <ul className="bottomSocial">
            <li><img className="bottomLogo" src="/assets/images/soso-logo-bottom-org.png" alt="" /></li>
            <li><i className="fab fa-facebook-f"></i></li>
            <li><i className="fab fa-twitter"></i></li>
            <li><i className="fab fa-instagram"></i></li>
            <li><i className="fab fa-linkedin-in"></i></li>
            <li><i className="fab fa-youtube"></i></li>
          </ul>          
        </div>

      </div>
    </footer>
    <CopyrightLayer />
  </React.Fragment>
);

export default Footer;