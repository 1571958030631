import React from 'react';
import { connect } from 'react-redux';
import Header from '../../components/header/header.component';
import Slider from '../../components/slider/slider.component';
import LastExperts from '../../components/lastexperts/lastexperts.component';
import TextContent from '../../components/textcontent/textcontent.component';
import TextContentGallery from '../../components/textcontentgallery/textgontentgallery.component';
import NewsSubscriber from '../../components/newssubscriber/newssubscriber.component';
import LastArticles from '../../components/lastarticles/lastarticles.component';
import Sponsors from '../../components/sponsors/sponsors.component';
import Footer from '../../components/footer/footer.component';

import './home.page.styles.scss';

class Home extends React.Component {

  
  componentDidMount(){

  }

  render(){
    return(
      <React.Fragment>
      
        <Header />
        <Slider />
        <LastExperts language={this.props.language} />
        <TextContent language={this.props.language} />
        <TextContentGallery language={this.props.language} />
        <NewsSubscriber language={this.props.language} />
        <LastArticles language={this.props.language} />
        <Sponsors language={this.props.language} />
        <Footer language={this.props.language} />


      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    language: state.project.language
  };
}

export default connect(mapStateToProps)(Home);