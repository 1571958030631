import React from 'react';
import { connect } from 'react-redux';
import Select from "react-dropdown-select";
import {options} from '../../utils/options';
import {regions} from '../../utils/regions';
import SliderParallax from '../sliderparallax/sliderparallax.component';
import './slider.styles.scss';

const Slider = ({language}) => (
  <div className="sliderWrapper">
    <div className="leftSlider">
      <h1 className="sosoSlogan">{language.keywords.slogan1}</h1>
      <div className="watchVideoButtonWrapper">
        <h3 className="video-caption-text">{language.keywords.watchPromotionVideo}</h3>
        <button className="video-play-button">
          <span></span>
        </button>
      </div>

    </div>
    <div className="rightSlider">
      <div className="filterBox1">
        <div className="signUpButtonWrapper">
          <button className="signUpButton">
            <img className="signUpButtonImg" src="/assets/images/signup-green.png" alt="" />
          </button>
          <h3 className="signUpButtonText">{language.keywords.wantToJoinToOurExperts}</h3>
          
        </div>
      </div>
      <div className="filterBox2">
        <div className="recommendButtonWrapper">
          <button className="recommendButton">
            <img className="recommendButtonImg" src="/assets/images/recommend-green.png" alt="" />
          </button>
          <h3 className="recommendButtonText">{language.keywords.wantToRecommendExpert}</h3>          
        </div>
      </div>
      <div className="filterBox3">
        <h2 className="findWomanExpertHeader">{language.keywords.findWomanExpert}</h2>
        <form name="filterForm" className="filterForm">
          <div className="filterFormWrapper">

            <div className="formRow">
              <div className="col-30">
                <label htmlFor="editKeyword">{language.keywords.keyword}</label>
              </div>
              <div className="col-70">
                <input type="text" name="editKeyword" placeholder={language.keywords.keyword} />
              </div>
            </div>

            <div className="formRow">
              <div className="col-30">
                <label htmlFor="editSector">{language.keywords.sector}</label>
              </div>
              <div className="col-70">
                  <Select
                    name="editSector"
                    searchable={false}
                    placeholder={language.keywords.pleaseSelectSector}
                    onCreateNew={(item) => console.log('%c New item created ', 'background: #555; color: tomato', item)}
                    options={options}
                    labelField="name"
                    valueField="email"
                    color="#80bd01"
                    onChange={(value) =>
                      console.log(`%c > onChange berat `, 'background: #555; color: tomato', value)
                    }
                  />                
              </div>
            </div>
            
            <div className="formRow">
              <div className="col-30">
                <label htmlFor="editProfession">{language.keywords.profession}</label>
              </div>
              <div className="col-70">
                  <Select
                    name="editProfession"
                    searchable={false}
                    placeholder={language.keywords.pleaseSelectProfession}
                    onCreateNew={(item) => console.log('%c New item created ', 'background: #555; color: tomato', item)}
                    options={options}
                    labelField="name"
                    valueField="email"
                    color="#80bd01"
                    onChange={(value) =>
                      console.log(`%c > onChange berat `, 'background: #555; color: tomato', value)
                    }
                  />                   
              </div>
            </div>
            
            <div className="formRow">
              <div className="col-30">
                <label htmlFor="editRegion">{language.keywords.regionOfInterest}</label>
              </div>
              <div className="col-70">
                  <Select
                    name="editRegion"
                    searchable={false}
                    placeholder={language.keywords.pleaseSelectRegion}
                    options={regions}
                    labelField="name"
                    valueField="name"
                    color="#80bd01"
                    onChange={(value) =>
                      console.log(`%c > onChange berat `, 'background: #555; color: tomato', value)
                    }
                  />                  
              </div>
            </div>
            
            <div className="formRow">
              <div className="col-30">
                <label htmlFor="editLanguage">{language.keywords.language}</label>
              </div>
              <div className="col-70">
                  <Select
                    className="select"
                    name="editLanguage"
                    searchable={false}
                    placeholder={language.keywords.pleaseSelectLanguage}
                    onCreateNew={(item) => console.log('%c New item created ', 'background: #555; color: tomato', item)}
                    options={options}
                    labelField="name"
                    valueField="email"
                    color="#80bd01"
                    onChange={(value) =>
                      console.log(`%c > onChange berat `, 'background: #555; color: tomato', value)
                    }
                  />                      
              </div>
            </div>            
          </div>
          <button className="submitFilterButton">
            <img src="/assets/images/find.png" alt="" /> {language.keywords.findExpert} 
          </button>
        </form>
      </div>            
    </div>
  </div>
);

const mapStateToProps = state => {
  return {
    language: state.project.language
  };
}

export default connect(mapStateToProps)(Slider);